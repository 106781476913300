<template>
    <div>
        <v-container fluid>
            <v-row >
                <v-col sm="12" md="12">
                    <v-text-field label="Search name or addrs" v-model="search" outlined dense hide-details rounded prepend-inner-icon="mdi-magnify"></v-text-field>
                </v-col>
                <v-col cols="12" md="12"  >
                    <v-list subheader two-line style="max-height:70vh; overflow:scroll" >

                        <v-subheader >Illium</v-subheader>
                        <v-list-item @click="$emit('select',wallet)" v-for="wallet in filtered_wallets.filter((w=>w.type=='system'))" :key="wallet.id" link>
                            <v-list-item-avatar> <v-icon class="ma-0">mdi-wallet</v-icon> </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title> {{wallet.name}}</v-list-item-title>
                                <v-list-item-subtitle>{{short_address(wallet.address)}}</v-list-item-subtitle>
         
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-list-item-action-text  style="float:right;" >
                                    <base-amount  style="text-align:right;" :amount="wallet.balance"></base-amount>
                                    <v-icon @click="$emit('select_setting', wallet)" class="ml-5" style="float:right;">mdi-dots-horizontal</v-icon>
                                </v-list-item-action-text>

                            </v-list-item-action>
                        </v-list-item>

                        <v-divider></v-divider>
                        <v-subheader >External</v-subheader>
                        <v-list-item @click="$emit('select',wallet)" v-for="wallet in filtered_wallets.filter((w=>w.type=='external'))" :key="wallet.id" link>
                            <v-list-item-avatar> <v-icon class="ma-0">mdi-wallet</v-icon> </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title>{{wallet.name}}</v-list-item-title>
                                <v-list-item-subtitle>{{short_address(wallet.address)}}</v-list-item-subtitle>
         
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-list-item-action-text>
                                    <base-amount :amount="wallet.balance"></base-amount>
                                </v-list-item-action-text>

                            </v-list-item-action>
                        </v-list-item>
                    </v-list>
                </v-col>
                <v-col cols="12" md="12">
                    <v-btn class="ma-0"  elevation="2" small block @click="$emit('add')" ><v-icon class="ma-1">mdi-plus</v-icon>Add wallet</v-btn>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
import BaseAmount from '../base-amount.vue'
export default {
    name: 'wallet-list',
    components:{
        BaseAmount
    },
    emits:[
        'select'
    ],
    data(){return {
        // wallets:[
        //     {id:1, name:"wallet1", balance:0 ,type:"system", address:"0xecd2c557A64C5A392d99d9523c0bE995Db4E8C48"},
        //     {id:2, name:"wallet1", balance:0 ,type:"system", address:"0xecd2c557A64C5A392d99d9523c0bE995Db4E8C48"},
        //     {id:3, name:"wallet1", balance:0 ,type:"external", address:"0xecd2c557A64C5A392d99d9523c0bE995Db4E8C48"},
        //     {id:4, name:"wallet1", balance:0 ,type:"external", address:"0xecd2c557A64C5A392d99d9523c0bE995Db4E8C48"},
        //     {id:5, name:"wallet1", balance:0 ,type:"system", address:"0xecd2c557A64C5A392d99d9523c0bE995Db4E8C48"},
        // ],
        search:'',
        }
    },
    props: { 
        //propName:{type:String,default:''},
        //data:{type:Object,default:Object},
        wallets:{type:Array,default:Array},
    },
    mounted(){
    
    },
    methods:{
        ...mapActions([]),
        short_address(address){
            const start = address.substring(0, 9)
            const end = address.substring(address.length - 4); 
            return `${start}...${end}`
        }
    },
    computed:{
        ...mapState([]),
        filtered_wallets(){
            return this.wallets.filter( (w) => {
               return w.name.toLowerCase().includes(this.search.toLowerCase()) ||
                      w.address.toLowerCase().includes(this.search.toLowerCase());
               })
        }
    },
    watch:{
    },
}
</script>
<style scoped lang="scss">
</style>