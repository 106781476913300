<template>
    <div>
        <v-container fluid>
            <v-row>
                <v-col cols="12" md="12">
                    <h3>
                        Import token into {{implementationId}}.{{stageId}}
                    </h3>
                </v-col>
                <v-col sm="12" md="12">
                    <div style="display:flex; align-items:center" >
                        <v-text-field 
                            label="Buy transaction hash" 
                            v-model="import_tx_hash"  
                            outlined dense rounded 
                            :loading="loading.search"
                            append-icon="mdi-plus"  
                            @click:append="get_transaction_details_by_hash"  ></v-text-field>
                    </div>
                </v-col>
                <v-col sm="12" md="12">
                    <slot></slot>
                </v-col>
                <v-col sm="12" md="12">
                    <center>
                        <app-swap-token-selector 
                            @selected_token="select_token"  
                            :preselectPair="preselected_token">
                        </app-swap-token-selector>
                    </center>
                    <!-- {{selected_token}} -->
                </v-col>
                <v-col sm="12" md="4">
                    <v-text-field v-model="tokens_held" rounded  label="Tokens received"  outlined dense type="number"></v-text-field>
                </v-col>
                <v-col sm="12" md="4">
                    <v-text-field v-model="eth_invested"  rounded label="Eth Spend" outlined dense type="number"></v-text-field>
                </v-col>
                <v-col sm="12" md="4">
                    <v-text-field v-model="gas_used" rounded  label="Gas Spend" outlined dense type="number"></v-text-field>
                </v-col>
                <v-col sm="12" md="12">
                    <h5> time </h5>
                    <time-display :time="tx_time"></time-display>
                    <span style="display:flex">
                        
                        <time-display :time="tx_time" mode="timesince"></time-display> <div class="ml-2">Ago</div>
                        </span>
                </v-col>
                <v-col cols="12" md="12">
                    <div v-if="selected_token">
                        <div v-if="mode == 'as_ip' ">
				<v-btn class="ma-0" elevation="2" small light block @click="add_selected_token_to_implementation"> 
					import token into agent
				</v-btn>
                        </div>
			<div v-if="mode == 'as_bag'">
				<v-btn class="ma-0" elevation="2" small light block @click="add_selected_token_to_implementation">
					Add bag and log import
				</v-btn>
			</div>
                    </div>
                </v-col>

            </v-row>
        </v-container>
    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
import AppSwapTokenSelector from '@/components/app_swap/app-swap-token-selector.vue'
import TimeDisplay from '@/components/time-display.vue'
export default {
    name: 'agent-import-token-as-ip',
    components:{
        AppSwapTokenSelector,
        TimeDisplay,
    },
    emits:[
        'imported'
    ],
    data(){return {
            selected_token:undefined,
            tokens_held:0,
            base_spend:0,
            import_tx_hash:'0x4999fad49518d4b37a1b78ebe610d858694449bcdf24a76ac2fbb1e5fba0fd6e',
            // import_tx_hash:'0xd3d180da30cbd2af7e649eb0c6580ad8afd108def7e6b7828ef26a6bb2fdd55d',
            eth_invested:0,
            gas_used:0,
            preselected_token :undefined,
            tx_time:undefined,
            loading:{
                search:false,
            }
        }
    },
    props: { 
        mode:{type:String,default:'as_ip'},
        implementationId:{type:Number,default:Number},
        stageId:{type:Number,default:Number},
        //data:{type:Object,default:Object},
        //data:{type:Object,default:Object},
        //list:{type:Array,default:Array},
    },
    mounted(){
    
    },
    methods:{
        ...mapActions(['manually_import_pair_to_implementation','get_bc_tx_details']),
        select_token(token){
            this.selected_token = token

        },
        get_transaction_details_by_hash(){
            this.loading.search = true
            this.get_bc_tx_details({qs:{
                    tx_hash:this.import_tx_hash, 
                    wallet:'0xecd2c557a64c5a392d99d9523c0be995db4e8c48'
                    },data:{}}).then((r)=>{

                this.eth_invested = r.data.eth_invested;
                this.gas_used =  r.data.gas_used;
                this.tokens_held = r.data.tokens_received[0].amount_decimaled;
                this.preselected_token = r.data.pairs[r.data.tokens_received[0].token];
                this.loading.search = false;
                this.tx_time  = r.data.tx_details.time_epoch

            })


        },
        add_selected_token_to_implementation(){
            if(this.mode == 'as_bag'){
                this.$emit('imported', {
                    pair_id:this.selected_token.pair.id,
                    tx_hash:this.import_tx_hash,
                    tokens_amount: this.tokens_held,
                    time_epoch: this.tx_time ,
                    eth_invested:this.eth_invested,
                    })
                return 
            }
            this.manually_import_pair_to_implementation({qs:{

                implementation_id:this.implementationId,
                pair_id: this.selected_token.pair.id,
                tokens_held: this.tokens_held,
                eth_invested:this.eth_invested,
                gas_used:this.gas_used,
                tx_hash:this.import_tx_hash,
                stage_id:this.stageId,
                time_epoch: this.tx_time ,


            },data:{}}).then((r)=>{
                this.$emit('imported')

            })
        }
    },
    computed:{
        ...mapState([]),
    },
    watch:{
    },
}
</script>
<style scoped lang="scss">
</style>
