<template>
  <div>
    <v-container fluid>
      <!-- totals row  -->
      <v-row>
        <v-col cols="12" md="4">
          <div class="totals-screen">
            <h4>{{ user_data.info.selected_team.name }} wallets</h4>
            <div class="total">
              <base-amount :amount="wallets_totals"></base-amount>
            </div>
            <div class="totals-footer" v-if="wallets">
              {{ wallets.length }} Wallets
            </div>
          </div>
        </v-col>
        <v-col cols="12" md="4">
          <div class="totals-screen">
            <h4>
              Wallet
              <span style="color: gray" v-if="selected_wallet">
                {{ selected_wallet.name }}</span
              >
              Bags
            </h4>
            <div class="total">
              <base-amount :amount="total_bag_equity"></base-amount>

            </div>
            <div class="totals-footer">
              <span v-if="bags != undefined"> {{bags.length}}</span>
               Bags</div>
          </div>
        </v-col>
        <v-col cols="12" md="4">
          <div class="totals-screen">
            <h4><div style="display: flex">Bag</div></h4>
            <div class="total">
              <v-sparkline
                :value="[0, 2, 5, 9, 5, 10, 3, 5, 0, 0, 1, 8, 2, 9, 0]"
                :gradient="['#f72047', '#ffd200', '#1feaea']"
                :smooth="10"
                :padding="8"
                :line-width="2"
                :stroke-linecap="'round'"
                :gradient-direction="'top'"
                :fill="false"
                :type="'trend'"
                :auto-line-width="false"
                auto-draw
              ></v-sparkline>
            </div>
            <div class="totals-footer">
              <base-amount class="ml-1" :amount="0"></base-amount>
            </div>
          </div>
        </v-col>
      </v-row>
      
      <!-- lists row -->
      <v-row>
        <v-col cols="12" md="4">
          <div class="list-wrapper" v-if="wallets">
            <wallet-list
              :wallets="wallets"
              @select=" (e) => { selected_wallet = e; } "
              @select_setting=" (e) => { selected_wallet = e; dialog.wallet_setting = true; } "
              ></wallet-list>
          </div>
          <div v-else>Loading ...</div>
        </v-col>
        <v-col cols="12" md="4">
          <div class="list-wrapper" v-if="bags != undefined">

            <bag-list :bags="bags" @request_new_bag_add="dialog.import_bag = true" 
            @select="(e)=> {selected_bag = e}" ></bag-list>
         
          </div>
        </v-col>
        <v-col cols="12" md="4">
          <div class="list-wrapper" v-if="logs != undefined">
            <bag-overview :bag="selected_bag" :logs="logs"></bag-overview>
          </div>
        </v-col>
      </v-row>
    
    </v-container>

    <!-- popup wallet settings  -->
    <v-dialog v-model="dialog.wallet_setting" max-width="70vw">
      <v-sheet rounded="sm" v-if="selected_wallet">
        <wallet-settings :walletId="selected_wallet.id"></wallet-settings>
        <wallet-settings></wallet-settings>
      </v-sheet>
    </v-dialog>

    <!-- popup import bag popup  --> 
    <v-dialog v-model="dialog.import_bag"  max-width="70vw" >
        <v-sheet rounded="sm">
            <agent-import-token-as-ip mode='as_bag' @imported="do_import_bag_into_wallet_tx" ></agent-import-token-as-ip>
        </v-sheet>
    </v-dialog>

  </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState, mapActions } from "vuex";
import AgentImportTokenAsIp from '../agent-import-token-as-ip.vue';
import BaseAmount from "../base-amount.vue";
import WalletSettings from "../wallet-settings.vue";
import BagList from "./bag-list.vue";
import BagOverview from "./bag-overview.vue";
import WalletBagsOverview from "./wallet-bags-overview.vue";
import WalletList from "./wallet-list.vue";
export default {
  name: "view-wallet-bags",
  components: {
    WalletBagsOverview,
    BagList,
    WalletList,
    BaseAmount,
    BagOverview,
    WalletSettings,
    AgentImportTokenAsIp,
  },
  emits: [],
  data() {
    return {
    wallets: undefined,
    bags:undefined,
    logs:undefined, 
    selected_wallet: undefined,
    selected_bag: undefined,
    dialog: {
      wallet_setting: false,
      import_bag:false,
    },

    total_bag_equity: 0 ,
    };
  },
  props: {
    //propName:{type:String,default:''},
    //data:{type:Object,default:Object},
    //list:{type:Array,default:Array},
  },
  mounted() {
    this.do_get_wallets();
  },
  methods: {
    ...mapActions(["team_wallets_list_wallets","get_bag_logs","get_wallet_bags", "wallet_bag_init_bag_import"]),
    do_get_wallets() {
      this.team_wallets_list_wallets({
        qs: {
          team_id: this.user_data.info.selected_team.id,
        },
        data: {},
      }).then((r) => {
        this.wallets = r.data;
      });
    },
    do_get_bag_list(){
      if(this.selected_wallet == undefined){ return false}
      this.bags = undefined
      this.get_wallet_bags({qs:{wallet_id:this.selected_wallet.id},data:{}}).then((r)=>{
        this.bags = r.data.bags 
        this.total_bag_equity = r.data.total_equity

      })

    },
    do_get_bag_logs(){
      if(this.selected_bag == undefined){ return false}
      this.logs = undefined
      this.get_bag_logs({qs:{bag_id:this.selected_bag.id},data:{}}).then((r)=>{
        this.logs = r.data.logs
      })
    },


    do_import_bag_into_wallet_tx(tx_data){
      console.log("Importing", tx_data);
      let payload = {
        wallet_id:this.selected_wallet.id,
        pair_id:tx_data.pair_id,
        tx_hash:tx_data.tx_hash,
        buy_time_epoch: tx_data.time_epoch ,
        tokens_amount:  tx_data.tokens_amount ,
        eth_invested:  tx_data.eth_invested ,
      }
      console.log("Payload_to_send", payload);
      this.wallet_bag_init_bag_import({qs:{},data:payload}).then((r)=>{
      this.dialog.import_bag = false
      this.do_get_bag_list()
      })

    },

    short_address(address) {
      const start = address.substring(0, 9);
      const end = address.substring(address.length - 4);
      return `${start}...${end}`;
    },
  },
  computed: {
    ...mapState(["user_data"]),
    wallets_totals() {
      if (this.wallets == undefined) {
        return 0;
      }
      function sumBalances(objects) {
        let total = 0;
        objects.forEach((obj) => {
          if (typeof obj.balance === "number") {
            total += obj.balance;
          }
        });
        return total;
      }
      return sumBalances(this.wallets);
    },
    total_equity(){
            
    },


  },
  watch: {
    selected_wallet(nv,ov){
      this.do_get_bag_list()
    },
    selected_bag(nv,ov){
      this.do_get_bag_logs()
    },

  },
};
</script>
<style scoped lang="scss">
.list-wrapper {
  background-color: #121212bd;
  backdrop-filter: blur(5px);
  padding: 1em;
  border-radius: 1em;
  height: 100%;
}
.list-title {
}
.totals-screen {
  background-color: #121212bd;
  backdrop-filter: blur(5px);
  padding: 1em;
  border-radius: 1em;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h4 {
    text-align: left;
    padding-top: 0px;
    margin-top: -0.9em;
    margin-left: -0.3em;
    color: rgba(255, 255, 255, 0.515);
  }
  .total {
    font-size: 2em;
    text-align: center;
  }
  .totals-footer {
    margin-bottom: -0.9em;
    margin-right: -0.3em;
    text-align: right;
  }
}
</style>