<template>
    <div>
        <v-container fluid>
            <v-row >
                <v-col sm="12" md="12">
                    <v-text-field label="Search name or addrs" v-model="search" outlined dense hide-details rounded prepend-inner-icon="mdi-magnify"></v-text-field>
                </v-col>
                <v-col cols="12" md="12"  >
                    <v-list subheader three-line style="max-height:80vh; overflow:scroll" >

                        <v-subheader >Logs</v-subheader>
                        <v-list-item @click="selected_log=log; dialog=true" v-for="log in filtered_logs" :key="log.id" link>
                            <v-list-item-content>
                                <v-list-item-title>{{log.subject}}</v-list-item-title>
                                <v-list-item-subtitle>{{(log.log)}}</v-list-item-subtitle>
         
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-list-item-action-text>
                                    <time-display :time="log.time_epoch"></time-display>
                                    <base-amount :amount="log.amount_base"></base-amount>
                                </v-list-item-action-text>

                            </v-list-item-action>
                        </v-list-item>

                      
                    </v-list>
                </v-col>
    
            </v-row>
        </v-container>
        
        <!-- popup Title  -->
        <v-dialog v-model="dialog"  max-width="70vw" >
            <v-sheet rounded="sm">
                <pre>
                    {{selected_log}}
                </pre>
            </v-sheet>
        </v-dialog>
    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
import BaseAmount from '../base-amount.vue'
import TimeDisplay from '../time-display.vue'
export default {
    name: 'bag-logs',
    components:{
        TimeDisplay,
        BaseAmount
    },
    emits:[
 
           ],
    data(){return {
        dialog:false,
        selected_log:undefined,
        //         logs:[
        //     {id:1, header:"log1", balance:0 ,type:"system", body:"lorem ipsum hashd sa dkas dkas dhjas jd ajd asjhda sjd asjhda sd asjhd asjdhasjd asjd asjhd ajuwe whei ciwe cwieh jfh e"},
        //     {id:2, header:"log1", balance:0 ,type:"system", body:"lorem ipsum hashd sa dkas dkas dhjas jd ajd asjhda sjd asjhda sd asjhd asjdhasjd asjd asjhd ajuwe whei ciwe cwieh jfh e"},
        //     {id:3, header:"log1", balance:0 ,type:"external", body:"lorem ipsum hashd sa dkas dkas dhjas jd ajd asjhda sjd asjhda sd asjhd asjdhasjd asjd asjhd ajuwe whei ciwe cwieh jfh e"},
        //     {id:4, header:"log1", balance:0 ,type:"external", body:"lorem ipsum hashd sa dkas dkas dhjas jd ajd asjhda sjd asjhda sd asjhd asjdhasjd asjd asjhd ajuwe whei ciwe cwieh jfh e"},
        //     {id:5, header:"log1", balance:0 ,type:"system", body:"lorem ipsum hashd sa dkas dkas dhjas jd ajd asjhda sjd asjhda sd asjhd asjdhasjd asjd asjhd ajuwe whei ciwe cwieh jfh e"},
        // ],
        search:'',
        

        }
    },
    props: { 
        logs:{type:Array,default:Array},
    },
    mounted(){
    
    },
    methods:{
        ...mapActions([]),
    },
    computed:{
        ...mapState([]),
                filtered_logs(){
            return this.logs.filter( (w) => {
               return w.log.toLowerCase().includes(this.search.toLowerCase()) ||
                      w.subject.toLowerCase().includes(this.search.toLowerCase());
               })
        }
    },
    watch:{
    },
}
</script>
<style scoped lang="scss">
</style>