<template>
    <div>
        <v-container fluid v-if="wallet_data">
            <v-row>
                <v-col cols="12" md="12">
                    <h2> {{wallet_data.name }} </h2>
                    <h5> {{ wallet_data.address }} </h5>
                </v-col>
                <v-col cols="12" md="4" style="display: flex; justify-content: center; align-items: center;"> <h4>{{ wallet_data.network }}</h4> </v-col>
                <v-col cols="12" md="4" style="display: flex; justify-content: center; align-items: center;"> 
                    <v-switch color="red" label="Armed" :hint="wallet_data.is_armed ? 'YES':'NO'" persistent-hint v-model="wallet_data.is_armed"></v-switch>
                </v-col>
                <v-col cols="12" md="4" style="display: flex; justify-content: center; align-items: center;"> 
                    <base-amount :amount="wallet_data.balance_base"></base-amount>
                    <v-btn class="ma-0"  elevation="2" x-small @click="get_selected_wallet_balance" fab> <v-icon class="ma-0">mdi-refresh</v-icon></v-btn>
                </v-col>
                <v-col cols="12" md="6"> 
                    <h4> Gas Settings </h4>
                    <v-text-field color="white" label="Gas Price Percentile" v-model="wallet_data.gas_price_percentile" rounded  outlined dense append-icon="mdi-information-outline" @click:append="gas_dialog = true" ></v-text-field> 
                    <v-text-field color="white" label="Max Gas Price Gwei" v-model="wallet_data.max_gas_price_gwei" rounded  outlined dense ></v-text-field>
                    <v-text-field color="white" label="Gas Limit Slack Multiplier" v-model="wallet_data.gas_limit_slack_multiplier" rounded  outlined dense ></v-text-field>
                </v-col>
                <v-col cols="12" md="6"> 
                    <h4>Trade Settings</h4>
                    <v-text-field color="white" label="Max Active Trades" v-model="wallet_data.max_active_trades" type="number" max="20" rounded  outlined dense ></v-text-field>
                    <v-text-field color="white" label="Max Buy Base" v-model="wallet_data.max_buy_base" type="number" rounded  outlined dense ></v-text-field>
                    <v-text-field color="white" label="Max Slippage on Buy" v-model="wallet_data.max_slippage_buy_pct" rounded  outlined dense ></v-text-field>
                    <v-text-field color="white" label="Max Slippage on Sell" v-model="wallet_data.max_slippage_sell_pct" rounded  outlined dense ></v-text-field>
                </v-col>
                <v-col sm="12" md="12">
                    <v-btn class="ma-0"  elevation="2" small  block @click="0" >save</v-btn>
                </v-col>
                <!-- <v-col cols="12" md="6"> 
                    <h4>bags</h4>
                    <table>
                        <tr v-for="asset in wallet_data.assets" :key="asset.id">
                            {{ asset }} 
                        </tr>
                    </table>
                </v-col>
                <v-col cols="12" md="6"> 
                    <h4> agent implements</h4>
                    <table>
                        <tr v-for="i in wallet_data.implements" :key="i.id">
                            {{ i }} 
                        </tr>
                    </table>
                </v-col> -->
            </v-row>
        </v-container>
    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
import BaseAmount from './base-amount.vue'
export default {
    name: 'wallet-settings',
    components:{
        BaseAmount
    },
    emits:[
    ],
    data(){return {
        wallet_data:undefined,
        }
    },
    props: { 
        //propName:{type:String,default:''},
        walletId:{type:Number,default:Number},
        //list:{type:Array,default:Array},
    },
    mounted(){
        this.do_get_wallet_data()
    },
    methods:{
        ...mapActions(['model_method']),
        do_get_wallet_data(){
            this.model_method({model:'Wallet',method:'page_get',obj_id:this.walletId}).then(r=>{
                this.wallet_data = r.data
            })
        },
        get_selected_wallet_balance(){
            this.model_method({model:'Wallet',method:'get_update_balance_base',obj_id:this.walletId}).then(r=>{
                this.wallet_data.balance_base = r.data.balance_base
            })
        }
    },
    computed:{
        ...mapState([]),
    },
    watch:{
        walletId(nv,ov){
            this.do_get_wallet_data()
        }
    },
}
</script>
<style scoped lang="scss">
</style>