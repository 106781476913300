<template>
    <div>
        <v-container fluid>
            <v-row >
                <v-col sm="12" md="12">

                    <v-text-field label="Search name or addrs" v-model="search" outlined dense hide-details rounded prepend-inner-icon="mdi-magnify"></v-text-field>
                </v-col>
                <v-col cols="12" md="12"  >
                    <v-list subheader three-line style="max-height:70vh; overflow:scroll" >

                        <v-list-item @click="$emit('select',bag)" v-for="bag in filtered_bags" :key="bag.id" link>
                            <v-list-item-avatar>
                                <v-icon class="ma-0">mdi-sack</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title>  <pair-link-to-page :pair="bag.pair"></pair-link-to-page> </v-list-item-title>
                                <v-list-item-subtitle>{{(bag.address)}}</v-list-item-subtitle>
         
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-list-item-action-text>
                                    <base-amount :amount="bag.equity"></base-amount>
                                </v-list-item-action-text>
                            </v-list-item-action>
                        </v-list-item>

                
                    </v-list>
                </v-col>
                <v-col cols="12" md="12">
                    <v-btn class="ma-0"  elevation="2" small block @click="$emit('request_new_bag_add')" ><v-icon class="ma-1">mdi-plus</v-icon>Add bag</v-btn>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
// Begin Component Imports
import BaseAmount from '../base-amount.vue'
// End Component Imports
import { mapState,mapActions } from 'vuex'
import PairLinkToPage from '../pair-link-to-page.vue'
export default {
    name: 'bag-list',
    components:{
        BaseAmount,
        PairLinkToPage
    },
    emits:[
        'request_new_bag_add'
    ],
    data(){return {
        //         bags:[
        //     {id:1, equity:0, balance:12038828283, pair:{ name:"token1", symbol:"tkn1", id:0,currency_address:'0x..00',pair_address:'0x..0'}},
        // ],
        search:'',

        }
    },
    props: { 
        //propName:{type:String,default:''},
        //data:{type:Object,default:Object},
        bags:{type:Array,default:Array},
    },
    mounted(){
    
    },
    methods:{
        ...mapActions([]),
    },
    computed:{
        ...mapState([]),
        filtered_bags(){
            return this.bags.filter( (w) => {
               return  w.pair.pair_address.toLowerCase().includes(this.search.toLowerCase()) ||
                      w.pair.currency_address_address.toLowerCase().includes(this.search.toLowerCase()) ||
                      w.pair.name.toLowerCase().includes(this.search.toLowerCase()) ||
                      w.pair.symbol.toLowerCase().includes(this.search.toLowerCase()) ;
               })
        },

    },
    watch:{
    },
}
</script>
<style scoped lang="scss">
</style>