<template>
    <div>
        <v-container fluid>
            <v-row>
                <v-col cols="12" md="12">
                    <bag-actions></bag-actions>
                </v-col>
                <v-col cols="12" md="12">
                    <bag-logs :logs="logs"></bag-logs>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
import BagActions from './bag-actions.vue'
import BagLogs from './bag-logs.vue'
export default {
    name: 'bag-overview',
    components:{
        BagLogs,
        BagActions,
    },
    emits:[
    ],
    data(){return {
        }
    },
    props: { 
        bag:{type:Object,default:Object},
        logs:{type:Array,default:Array},
    },
    mounted(){
    
    },
    methods:{
        ...mapActions([]),
    },
    computed:{
        ...mapState([]),
    },
    watch:{
    },
}
</script>
<style scoped lang="scss">
</style>