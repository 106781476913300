<template>
    <div>
        <v-container fluid class="ma-0 pa-0">
            <v-row>
                <v-col cols="12" md="12" class="ma-0 pa-0">
                    <v-btn class="ma-1"  elevation="2" small block @click="0" >Sell </v-btn>
                    <v-btn class="ma-1"  elevation="2" small block @click="0" > Ape more </v-btn>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
export default {
    name: 'bag-actions',
    components:{
    },
    emits:[
    ],
    data(){return {
        }
    },
    props: { 
        //propName:{type:String,default:''},
        //data:{type:Object,default:Object},
        //list:{type:Array,default:Array},
    },
    mounted(){
    
    },
    methods:{
        ...mapActions([]),
    },
    computed:{
        ...mapState([]),
    },
    watch:{
    },
}
</script>
<style scoped lang="scss">
</style>