<template>
    <div>
        <v-text-field placeholder="Search Name, Id or Address" v-model="search_str" outlined dense rounded append-icon="mdi-magnify" @click:append="search" :loading="is_loading"></v-text-field>
        <!-- {{search_str}}
        {{search_results}} -->
    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
export default {
    name: 'pair-search-form',
    components:{
    },
    emits:[
        'searched'
    ],
    data(){return {
        is_loading:false,
        search_str:'captain inu',
        search_results:[],
        }
    },
    props: { 
        //propName:{type:String,default:''},
        //data:{type:Object,default:Object},
        //list:{type:Array,default:Array},
    },
    mounted(){
    
    },
    methods:{
        ...mapActions(['swap_app_search_pair']),
        search(){
            this.is_loading = true;
            this.swap_app_search_pair({qs:{search_str:this.search_str},data:{}}).then((r)=>{
                this.is_loading = false;
                this.search_results = r.data
                this.$emit('searched',this.search_results.found_pairs)

            })
        }
    },
    computed:{
        ...mapState([]),
    },
    watch:{
    },
}
</script>
<style scoped lang="scss">
</style>