<template>
    <div>
        <v-btn @click=" dialog_select_token= true" elevation="2" class="ma-0" color="default" large rounded  > 
            <div v-if="selected_pair == undefined">
                SELECT TOKEN
                <v-icon class="ma-0">mdi-menu-down</v-icon>
            </div>
            <div v-else>
                <v-icon class="ma-0" color="orange" >mdi-minus-circle-multiple</v-icon>
                {{token_symbol}}
            </div>
        </v-btn>

        <v-dialog v-model="dialog_select_token"  max-width="70vw" >
            <v-sheet rounded="lg">
                <v-container fluid>
                    <v-row>
                        <v-col cols="12" md="12">
                            <h5>select a token</h5>
                            <pair-search-form @searched="searched"></pair-search-form>
                        </v-col>
                        <v-col cols="12" md="12">
                            <v-list>
                                <v-list-item v-for="p in search_results" :key="p.id">
                                    <v-btn class="ma-1"  elevation="2" small @click="select_pair(p)" >select</v-btn>
                                    <pair-link-to-page :pair="p" ></pair-link-to-page>
                                </v-list-item>
                            </v-list>
                        </v-col>
                    </v-row>
                </v-container>

            </v-sheet>
        </v-dialog>

    </div>
</template>
<script>
// Begin Component Imports
import PairLinkToPage from '../pair-link-to-page.vue'
import PairSearchForm from '../pair-search-form.vue'

// End Component Imports
import { mapState,mapActions } from 'vuex'
export default {
    name: 'app-swap-token-selector',
    components:{
        PairLinkToPage,
        PairSearchForm,
    },
    emits:[
        'selected_token'
    ],
    data(){return {
        dialog_select_token: false, 
        selected_pair: undefined,
        token_name:'',
        token_symbol:'',
        search_results:[],
        price: undefined,

        }
    },
    props: { 
        //propName:{type:String,default:''},
        preselectPair:{type:Object,default:undefined},
        //list:{type:Array,default:Array},
    },
    mounted(){
        if(this.preselectPair != undefined ){ 
            const token_has_name_symbol = this.preselectPair.name != undefined
            if (token_has_name_symbol){ this.selected_pair = this.preselectPair }
            else{this.selected_pair = this.preselectPair } 
            }
    },
    methods:{
        ...mapActions(['swap_app_pair_price']),
        select_pair(pair){
            this.selected_pair = pair
            this.dialog_select_token = false;
        },
        searched(results){
            this.search_results = results

        },

    },
    computed:{
        ...mapState([]),
    },
    watch:{
        selected_pair(nv,ov){
            console.log("Selected pair changed", nv);

            this.swap_app_pair_price({ qs:{pair_id:nv.id}, data: {}}).then(r=>{
                
                this.price = r.data
                this.token_name = r.data.pair.name
                this.token_symbol = r.data.pair.symbol
                console.log(r.data);
                this.$emit('selected_token', {
                    pair:nv, 
                    price:this.price,
                    amount:0,
                    balance:undefined,

                    })
            })
        },
        preselectPair(nv,ov){
            if(this.preselectPair != undefined ){ this.selected_pair = this.preselectPair}
        }

    },
}
</script>
<style scoped lang="scss">
</style>